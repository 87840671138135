<template>
	<div>
		<vue-progress-bar />
		<!-- Rewards default page and referrals -->
		<div v-show="!loadingPage">
			<div v-if="!customInvite">
				<Hero
					:referredByName="referredByName"
					:referredBy="referredBy"
					:customInvite="customInvite"
					:mphPrice="mph_price"
					:inviteMessage="inviteMessage"
				/>
				<HowItWorks :mphPrice="mph_price" />
				<Footer
					:disclaimerContent="2"
					:emptyFooter="referredBy !== ''"
					:airdropPage="true"
					:referredBy="referredBy"
					:customInvite="customInvite"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import Hero from "@/components/rewards/Hero";
import HowItWorks from "@/components/rewards/HowItWorks";
import Footer from "@/components/partials/Footer";
import { i18n } from "../plugins/i18n";
import Cookie from "js-cookie";
import Invites from "../services/Invites";
import markets from "../services/Markets";
import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_API;

export default {
	name: "Rewards",
	components: {
		Hero,
		HowItWorks,
		Footer,
	},
	data() {
		return {
			loadingPage: true,
			referredBy: "",
			customInvite: "",
			inviteMessage: "",
			referredByName: "",
			airdropAmount: 0,
			referralBonus: 0,
			lang: "en",
			mph_price: 0.015,
			headParams: {
				title: this.$t("meta.INVITE_TITLE"),
				description: this.$t("meta.INVITE_META_DESC"),
				meta_title: this.$t("meta.INVITE_META_TITLE"),
			},
			custom_invite_list: null,
			affiliateSaved: false
		};
	},
	async created() {
		if (!this.custom_invite_list || this.custom_invite_list.length < 1) {
			try {
				const data = await Invites.getInviteList();
				this.custom_invite_list = data.data;
			} catch (err) {
				this.custom_invite_list = [];
			}
		}
		// Get Referral ID
		if (this.$route.params.referred_by) {
			this.referredBy = this.$route.params.referred_by.toLowerCase();
		}
		// Load the custom invite from the url parameters
		if (this.$route.params.custom_invite) {
			this.customInvite = this.$route.params.custom_invite.toLowerCase();

			// If custom invite does not exist, redirect user to homepage
			if (!this.customInviteContent("exists").exists) {
				this.$router.replace("/");
				return;
			} else {
				i18n.locale = this.customInviteContent("exists").lang;
				Cookie.set("locale", this.customInviteContent("exists").lang);
				this.lang = this.customInviteContent("exists").lang;
				// Load custom message for Hero page
				this.inviteMessage = this.customInviteContent("message");
			}
		}

		if (this.referredBy) {
			localStorage.setItem("airdrop_referred_by", this.referredBy);
			localStorage.removeItem("airdrop_custom_invite");
		}
		if (this.customInvite) {
			localStorage.setItem("airdrop_custom_invite", this.customInvite);
			localStorage.removeItem("airdrop_referred_by");
			this.$router.replace("/");
			return;
		}
	},
	async mounted() {
		this.$Progress.start();

		let mph_price = JSON.parse(localStorage.getItem("mph_price") || "{}");

		if (
			!mph_price ||
			!mph_price.timestamp ||
			mph_price.timestamp < Date.now() - 1000 * 60 * 60 * 24
		) {
			const mph = await markets.getMPHPrice();
			mph_price = { timestamp: Date.now(), mph_price: Number(mph.data.price) };
			localStorage.setItem("mph_price", JSON.stringify(mph_price));
		}

		this.mph_price = mph_price.mph_price || 0;

		this.loadingPage = false;
		this.$Progress.finish();

		// Set MPH Reward for Page Title and Metatags
		const amount = this.formatUSDEstimate(100000, this.mph_price, 50);
		this.headParams.title = i18n.t("meta.INVITE_TITLE", { amount });
		this.headParams.meta_title = i18n.t("meta.INVITE_META_TITLE", { amount });
		this.$emit("updateHead");

		document.dispatchEvent(new Event("x-app-rendered"));

		this.saveAffiliateData()
	},
	methods: {
		saveAffiliateData() {
			if (!this.affiliateSaved) {
				this.affiliateSaved = true;
				const id = localStorage.getItem("affiliate_db_id");

				const affiliate_id = localStorage.getItem("affiliate_id");
				const at_gd = localStorage.getItem("at_gd");
				const ref = localStorage.getItem("ref");
				const affiliate_campaign =
					localStorage.getItem("affiliate_campaign") || "";
				const affiliate_referrer =
					localStorage.getItem("affiliate_referrer") || "";
				const affiliate_utm_source =
					localStorage.getItem("affiliate_utm_source") || "";
				const affiliate_utm_medium =
					localStorage.getItem("affiliate_utm_medium") || "";
				const affiliate_utm_campaign =
					localStorage.getItem("affiliate_utm_campaign") || "";
				const affiliate_path = localStorage.getItem("affiliate_path") || "";

				const affiliate_language = localStorage.getItem("affiliate_language");
				let affiliate_browser_language = localStorage.getItem("affiliate_browser_language");
				let airdrop_referred_by = this.$route.params?.referred_by?.toLowerCase();
				let airdrop_custom_invite = this.$route.params?.custom_invite?.toLowerCase();
				if (airdrop_referred_by) {
					airdrop_custom_invite = undefined
				}
		
				if (!affiliate_browser_language) {
					const browser_language = navigator.language || navigator.userLanguage; //no ?s necessary
					if (browser_language) {
						affiliate_browser_language = browser_language
					}
				}

				if (ref) {
					axios
						.post("/web/sendAnalytics", {
							event: "tapfiliate_click",
							data: {
								ref,
								source_id: localStorage.getItem("source_id"),
								referrer: affiliate_referrer,
								landing_page: window.location.href,
								user_agent: window.navigator.userAgent,
								id,
								airdrop_referred_by,
								airdrop_custom_invite
							},
						})
						.then((result) => {
							try {
								if (result?.data?.id) {
									localStorage.setItem('affiliate_db_id', result.data.id.toString())
								}
								
							} catch (err) {
								console.log("Error in saveAffiliateData", err);
							}
							this.affiliateSaved = true;
						});
				}

				if (affiliate_id || at_gd || airdrop_referred_by || airdrop_custom_invite) {
					axios
						.post("/web/sendAnalytics", {
							event: "affiliate_data",
							data: {
								affiliate_id,
								affiliate_campaign,
								affiliate_referrer,
								affiliate_utm_source,
								affiliate_utm_medium,
								affiliate_utm_campaign,
								affiliate_path,
								at_gd,
								ref,
								id,
								affiliate_language,
								affiliate_browser_language,
								airdrop_referred_by,
								airdrop_custom_invite
							},
						})
						.then((result) => {
							try {
								
								if (result?.data?.id) {
									localStorage.setItem('affiliate_db_id', result.data.id.toString())
								}
								
							} catch (err) {
								console.log("Error in saveAffiliateData", err);
							}
							this.affiliateSaved = true;
						});
				}
			}
		},
		// Custom URLs for Airdrop Invites
		// Key matches to url (ie /fractal) and value is message for Hero subtitle
		customInviteContent(type) {
			// const customGroups = {
			//   // 'fractal': 'CUSTOM',
			//   quorainnercircle: "CUSTOM",
			//   cryptouniverse: "CUSTOM",
			//   alexcrypto: "CUSTOM",
			//   keybase: "CUSTOM",
			//   reddit: "Hey Reddit, welcome to the Morpher Airdrop!",
			//   tallguytycoon: "Tall Guy Tycoon invited you to the Morpher Airdrop!",
			//   cryptobuzz: "CryptoBuzz invited you to join the Morpher Airdrop!",
			//   tehmoonwalker: "tehMoonwalkeR invited you to the Morpher Airdrop!",
			//   cryptoexplorer: "CryptoExplorer invited you to the Morpher Airdrop!",
			//   tokenomi: "Tokenomi invited you to the Morpher Airdrop!",
			//   cryptoanalyzer: "Crypto Analyzer invited you to the Airdrop!",
			//   cryptocrunch: "CryptoCrunchApp invited you to the Airdrop!",
			//   bitcointalk: "Hey BitcoinTalk, welcome to Morpher!",
			//   ghayas: "Ghayas invited to the Morpher Airdrop!",
			//   picpukk: "PICPUKK invited you to the Morpher Airdrop!",
			//   traderjh: "Welcome, you've been invited to the Morpher Airdrop!",
			//   gift: "Exclusive offer to claim the Morpher Airdrop.",
			//   altcoinpost: "Welcome Altcoinpost fans to the Morpher Airdrop!",
			//   federicosellitti: "Federico invited you to the Morpher Airdrop!",
			//   cryptogrowth: "Welcome CryptoGrowth fans to the Morpher Airdrop!",
			//   webleone: "Welcome Webleone fans to the Morpher Airdrop!",
			//   coinfunda: "Welcome CoinFunda fans to the Morpher Airdrop!",
			//   redeeem: "Welcome Redeeem users to the Morpher Airdrop!",
			//   neconnected: "Welcome, you've been invited to the Morpher Airdrop!",
			//   tetrisotoko: "Tetris invited you to the Morpher Airdrop!",
			//   julesquinha: "Julesquinha invited you to the Morpher Airdrop!",
			//   oespanhol: "O Espanhol invited you to the Morpher Airdrop!",
			//   // Our own custom links
			//   digitalking: "Digital King invited you to the Morpher Airdrop!",
			//   mjonas: "Maiyer invited you to the Morpher Airdrop!",
			//   pbauer: "Pieter invited you to the Morpher Airdrop!",
			//   steffanie: "Steffanie invited you to the Morpher Airdrop!",
			//   chimaok: "Chima invited you to the Morpher Airdrop!",
			//   martin: "Martin invited you to the Morpher Airdrop!",
			//   draper: "Welcome to the Morpher Airdrop!",
			//   telegram: "Welcome to the Morpher Airdrop!",
			//   quora: "Welcome to the Morpher Airdrop!",
			//   discord: "Welcome to the Morpher Airdrop!",
			//   vk: "Welcome to the Morpher Airdrop!",
			//   custom_invite: "Welcome to the Morpher Airdrop!",
			// };

			// const customRussianGroups = {
			//   "privet-1": "CUSTOM",
			//   "privet-2": "CUSTOM",
			//   "privet-3": "CUSTOM",
			//   "privet-4": "CUSTOM",
			//   "privet-5": "CUSTOM",
			//   "nov1kov_bm": "CUSTOM",
			// };

			// let lang = "";
			// let langGroup;

			// const existsEn = Object.keys(customGroups).includes(this.customInvite);
			// const existsRu = Object.keys(customRussianGroups).includes(
			//   this.customInvite
			// );

			// if (existsEn) {
			//   lang = "en";
			//   langGroup = customGroups;
			// } else if (existsRu) {
			//   lang = "ru";
			//   langGroup = customRussianGroups;
			// }

			let invite_data;
			if (this.custom_invite_list && this.custom_invite_list.length > 0) {
				try {
					invite_data = this.custom_invite_list.find(
						(invite) => invite.custom_invite == this.customInvite
					);
				} catch (err) {
					this.custom_invite_list = [];
				}
			}

			switch (type) {
				case "exists":
					return {
						lang: invite_data ? invite_data.language || "en" : "en",
						exists: invite_data ? true : false,
					};
				case "message":
					return invite_data ? invite_data.custom_text || "" : "";
				default:
					break;
			}
		},
	},
	head() {
		return {
			title: {
				inner: this.headParams.title,
			},
			meta:
				this.$route?.params?.referred_by == "referred_by"
					? [
							{
								name: "description",
								content: this.headParams.description,
								id: "desc",
							},
					  ]
					: [
							{
								name: "description",
								content: this.headParams.description,
								id: "desc",
							},

							{ property: "og:type", content: "website", id: "og-type" },
							{
								property: "og:title",
								content: this.headParams.meta_title,
								id: "og-title",
							},
							{
								property: "og:description",
								content: this.headParams.description,
								id: "og-description",
							},
							{
								property: "og:image",
								content: "https://www.morpher.com/img/og_invite_fb.png",
								id: "og-image",
							},
							{
								property: "og:url",
								content: "https://www.morpher.com" + this.$route.path,
								id: "og-url",
							},
							{
								property: "twitter:card",
								content: "summary_large_image",
								id: "twitter-card",
							},
							{
								property: "twitter:site",
								content: "@morpher_io",
								id: "twitter-site",
							},
							{
								property: "twitter:image",
								content: "https://www.morpher.com/img/og_invite_tw.png",
								id: "twitter-image",
							},
					  ],

			link:
				this.$route?.params?.referred_by == "referred_by"
					? []
					: [
							{
								rel: "canonical",
								href: "https://www.morpher.com" + this.$route.path,
								id: "canonical",
							},
					  ],
		};
	},
};
</script>
